<template>
    <div>
        <div class="container">
            <h1 class="text-center fs-xs-30 px-xs-4">Detective privado no Sul de Portugal?</h1>
            <article class="col-lg-11 px-0 mx-auto fs-sm-17 text-center mt-4 fw-500">
                Precisando de <b>Detective Privado para sua Empresa</b> em <b>Portugal?</b>
                A ABRAIP Detectives está em <b>Portugal</b> para poder lhe ajudar.
                Somos especializados em <b>casos empresarias, contra inteligência empresarial, varredura inteligente e verificação da conduta de sócios e/ou funcionários,</b> sempre executando nossos serviços de forma rápida, discreta e sigilosa, ajustados de acordo com a necessidade, exigência e orçamento de cada cliente. Não importa a complexidade do seu caso, nossos detectives estão preparados para atender sua demanda de forma <b>eficaz e profissional,</b> proporcionando assim o esclarecimento de suas dúvidas, a satisfação e a paz de espírito que você necessita e merece.
            </article>
            <div class="d-flex justify-content-center row mx-auto mt-30">
                <BtnLigueAgora class="col-xs-10" />
                <BtnFaleNoWhatsapp class="mt-xs-2 col-xs-10 ml-sm-3" />
            </div>
        </div>
        <section class="pt-80">
            <div class="container">
                <h3 class="text-uppercase fs-xs-25 fw-600 text-center text-dark">
                  Encontre um detetive na sua cidade
                </h3>
                <div v-for="(letra, index) in cidadesPorLetra" :key="index" class="position-relative overflow-hidden mt-40 col-12 d-flex row mx-auto pr-0 pt-1 pb-30 pl-45">
                    <div class="d-flex div-bolinha-letra-cidade justify-content-center align-items-center">
                        <h5 class="text-dark fw-600 mb-0">
                            {{ letra[0].nome.charAt(0).toUpperCase() }}
                        </h5>
                    </div>
                    <div class="borda-top-preto-separation"></div>
                    <div v-for="n in 3" :key="n" class="col-lg px-0 mt-4">
                        <a v-for="(cidade, i) in colunaCidades(letra, n)" :key="i" @click.prevent="navigateToCity(cidade.rota)" class="d-flex text-preto-cidades fs-sm-17 fw-600 btn align-items-center">
                            <i class="fa fa-angle-right fw-300 fs-25 mr-1"></i> {{ cidade.nome }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BtnLigueAgora from '../../../buttons/btn-outline-ligue-agora'
import BtnFaleNoWhatsapp from '../../../buttons/btn-outline-fale-no-whatsapp'

export default {
  components: {
    BtnLigueAgora,
    BtnFaleNoWhatsapp
  },
  data () {
    return {
      cidadesPorLetra: [
        [{ nome: 'Alcácer do Sal', rota: 'alcácer-do-sal' }, { nome: 'Albufeira', rota: 'albufeira' }, { nome: 'Alcoutim', rota: 'alcoutim' }, { nome: 'Aljezur', rota: 'aljezur' }],
        [{ nome: 'Beja', rota: 'beja' }, { nome: 'Borba', rota: 'borba' }],
        [{ nome: 'Castro Marim', rota: 'castro-marim' }],
        [{ nome: 'Elvas', rota: 'elvas' }, { nome: 'Estremoz', rota: 'estremoz' }, { nome: 'Évora', rota: 'évora' }],
        [{ nome: 'Faro', rota: 'faro' }],
        [{ nome: 'Lagoa', rota: 'lagoa' }, { nome: 'Lagos', rota: 'lagos' }, { nome: 'Loulé', rota: 'loulé' }],
        [{ nome: 'Monchique', rota: 'monchique' }, { nome: 'Montemor-o-Novo', rota: 'montemor-o-novo' }, { nome: 'Moura', rota: 'moura' }],
        [{ nome: 'Olhão', rota: 'olhão' }],
        [{ nome: 'Portimão', rota: 'portimão' }, { nome: 'Ponte de Sor', rota: 'ponte-de-sor' }, { nome: 'Portalegre', rota: 'portalegre' }],
        [{ nome: 'Reguengos de Monsaraz', rota: 'reguengos-de-monsaraz' }],
        [{ nome: 'Silves', rota: 'silves' }, { nome: 'São Brás de Alportel', rota: 'são-brás-de-alportel' }, { nome: 'Santiago do Cacém', rota: 'santiago-do-cacém' }, { nome: 'Serpa', rota: 'serpa' }, { nome: 'Sines', rota: 'sines' }],
        [{ nome: 'Tavira', rota: 'tavira' }],
        [{ nome: 'Vendas Novas', rota: 'vendas-novas' }, { nome: 'Vila Nova de Santo André', rota: 'vila-nova-de-santo-andré' }, { nome: 'Vila Real de Santo António', rota: 'vila-real-de-santo-antónio' }, { nome: 'Vila do Bispo', rota: 'vila-do-bispo' }]
      ]
    }
  },
  methods: {
    navigateToCity (rota) {
      this.$router.push(`/detective-privado-no-sul-de-portugal/detective-privado-em-${rota}`)
      this.$smoothScroll({
        scrollTo: document.getElementById('sobre'),
        duration: 500,
        offset: -120
      })
    },
    colunaCidades (grupo, n) {
      const tamanhoColuna = Math.ceil(grupo.length / 3)
      const inicio = (n - 1) * tamanhoColuna
      const fim = inicio + tamanhoColuna
      return grupo.slice(inicio, fim)
    }
  },
  metaInfo: {
    htmlAttrs: {
      lang: 'pt-BR'
    },
    title: 'Detective Privado em Norte | Braga | Barcelos | Felgueiras | Guimarães | Maia | Matosinhos | Santo Tirso',
    meta: [{ name: 'description', content: 'Somos uma agência de detectives focada em casos empresarias, contra inteligência empresarial, varredura inteligente, verificação da conduta de sócios e/ou funcionários e muito mais!' }]
  }
}
</script>

<style lang="scss" scoped>
/* Adicione seus estilos aqui */
</style>
